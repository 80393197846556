@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");

* {
  font-family: "Baloo 2", cursive;
}

.App {
  width: 100%;
  height: 100%;
}

body {
  background: url("https://frontend-assets.buildbox.com/web-gen-ai/background@2x.webp") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: left top;
  background-size: auto 100%;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  body {
    background: url("https://frontend-assets.buildbox.com/BB_AI_Homepage_Hero_Background_2160x900_blank.jpg") no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: top left;
  }
}
