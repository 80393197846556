.container {
  height: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  flex-wrap: wrap;
  /* margin-right: 10px; */
  /* margin-left: 10px; */
  width: 100%;
}

.buttonContainerMobile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10%;
  padding-top: 1.5em;
  box-sizing: border-box;
  z-index: 10;
  /* flex-direction: row-reverse; */
  /* margin-bottom: 1em; */
  /* flex-wrap: wrap; */
  /* margin-right: 2em; */
  /* margin-left: 10px; */
  /* width: 100%; */
  /* margin: 1em; */
  /* width: 100%; */
  /* position: fixed; */
}

.buttonGroup {
  margin-left: auto;
  margin-right: auto;
}
.promptFormContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.promptForm {
  padding: 5em;
  background-color: #222222;
  border-radius: 0.5em;
  min-height: 85vh;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 1em;
  border-radius: 0.5em;
  max-width: 1200px;
}

.inputFieldWrapper {
  height: 15%;
  padding: 1.5em 0;
  box-sizing: border-box;
}

.inputField {
  /* margin: 1em; */
  align-self: flex-end;
  border: none;
  padding: 0.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.sendIcon {
  /* transform: rotate(-30deg); */
  cursor: pointer;
}

.sendIcon.disabled {
  opacity: 0.15;
}

.promptFeedback {
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  overflow-y: scroll;
  border: 1px solid #505050;
  color: #e0e0e0;
  font-size: 17px;
  flex-basis: 70%;
  box-sizing: border-box;
  background-color: #222222;
  justify-content: flex-start;
}

.promptFeedback.justifyEnd {
  justify-content: flex-end;
}

/* .promptFeedback > div:nth-child(even) {
  background-color: #222222;
}

.promptFeedback > div:nth-child(odd) {
  background-color: #505050;
} */

.promptFeedback > div:nth-last-child(2) {
  border-bottom: none;
}

.promptFeedback > div:first-child {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.promptFeedback::-webkit-scrollbar {
  display: none;
}

.roboManContainer {
  height: 15%;
}

.roboMan {
    height: 120%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: -5%;
}

.dispText {
  color: #fff;
  margin-top: 15rem;
  padding: 2%;
}

.dispText h1 {
  font-size: 70px;
  text-align: center;
  margin: 0;
}

.dispText p {
  font-size: 43px;
  text-align: center;
  margin: 0;
}

.generalBtnSpacing {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 1em;
}

.defaultBtn {
  background-color: #505050;
  color: #fff;
  border-color: #505050;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 1em;
}

@media (max-width: 434px) {
  .promptForm {
    min-height: 75vh;
    max-height: 75vh;
  }
}

.promptFormMobile {
  background-color: transparent;

  display: flex;
  flex-direction: column;
  padding: 1em;
  height: 90%;
  box-sizing: border-box;
}

.promptContainer {
  display: flex;
  flex-direction: column;
  background-color: #505050;
  /* border: 1px solid #505050; */
  color: e0e0e0;
  max-height: 85vh;
}
