.completedUploadWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.qrWrapper {
    /* background: #fff; */
    /* color: #fff; */
}

.poweredBy {
    color: #e0e0e0;
    padding: 1em;
}

.poweredByContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.poweredByText {
    font-size: larger;
    margin-right: 1em;
}

.poweredByTextTwo {
    font-size: 2.5em;
    margin-left: 0.5em;
    font-weight: bold;
}

.poweredByTextThree {
    margin: 0;
    font-size: 1.3em;
    font-weight: bold;
    text-align: right;
    margin-right: 4.5em;
}

.poweredByLearnMore {
    text-align: center;
}
